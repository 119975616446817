import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Hero';
import Stats from '../components/Stats';

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Glibx - Blockchain Infrastructure, Lab & Venture</title>
        <meta name="description" content="Glibx is at the forefront of blockchain technology,we provide a robust validator nodes for blockchains , we build blockchain protocols, and investing in new promising blockchain projects." />
        <meta name="keywords" content="Blockchain, Infrastructure, Lab, Venture, Technology, Validator Nodes, Protocols, Investments" />
        <meta property="og:title" content="Glibx - Blockchain Infrastructure, Lab & Venture" />
        <meta property="og:description" content="Glibx is at the forefront of blockchain technology, robust validator nodes, blockchain protocols, and investing in new promising blockchain projects." />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://glibx.com/glibx-logo.png" />
        <meta property="og:url" content="https://glibx.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Glibx - Blockchain Infrastructure, Lab & Venture" />
        <meta name="twitter:description" content="Glibx is at the forefront of blockchain technology, running validator nodes, building blockchain protocols, and investing in new promising blockchain projects." />
        <meta name="twitter:image" content="https://glibx.com/glibx-logo.png" />
      </Helmet>
      <Hero />
      {/* <Stats /> */}
    </>
  );
};

export default Home;
