import React from 'react';
import { Box, Typography, Container } from '@mui/material';

const ComingSoon: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Container maxWidth="md">
      <Box textAlign="center" py={10}>
        <Typography variant="h3">{title}</Typography>
        <Typography variant="h5" color="textSecondary">
          This page is under construction. Check back soon!
        </Typography>
      </Box>
    </Container>
  );
};

export default ComingSoon;
