// In src/pages/AboutUs.tsx
import React from 'react';
import ComingSoon from '../components/ComingSoon';

const Projects = () => {
  return <ComingSoon title="Projects" />;
};

export default Projects;

